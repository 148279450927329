
import localization from '~/mixins/localization'

export default {
  mixins: [localization],

  props: {
    options: {
      type: [Array, Object],
      default: () => [],
    },

    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: 'auswählen',
    },

    value: {
      type: [String, Object],
      default: null,
    },

    rules: {
      type: [String, Object],
      default: '',
    },

    bgColor: {
      type: String,
      default: 'gray',
    },

    validationMode: {
      type: String,
      default: 'aggressive',
    },

    bold: {
      type: Boolean,
      default: false,
    },

    instructions: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      innerValue: this.value,
    }
  },

  computed: {
    mappedOptions() {
      return Object.entries(this.options).map(([label, value]) => ({
        label: this.localizedLabel(this.options[label] || this.options[value]),
        value,
      }))
    },
  },

  watch: {
    innerValue() {
      this.$emit('input', this.innerValue)
    },

    value() {
      this.innerValue = this.value
    },
  },
}
